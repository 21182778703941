import logo from '../../assets/logos/LOGO_footer.png'

function Footer () {
  return (
    <footer>
      <img src={logo} alt="logo de Kasa" />
      <p>© 2020 Kasa. All rights reserved</p>
    
    </footer>
  )
}

export default Footer